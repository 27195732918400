import React from "react";
import { Inject, ScheduleComponent, ViewDirective, ViewsDirective, Day, Week, Month, Agenda } from "@syncfusion/ej2-react-schedule";
import MainContent from "../../components/MainContent.component";
import { registerLicense } from "@syncfusion/ej2-base";
import { Stack } from "@mui/material";

// Registering Syncfusion license key
registerLicense("Ngo9BigBOggjHTQxAR8/V1NDaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFiW39dcH1XT2BZVUdxVg==");

const SchedulerPage = () => {
  const data = [
    {
      Id: 1,
      Subject: "Meeting",
      StartTime: new Date(2024, 3, 1, 10, 0),
      EndTime: new Date(2024, 3, 1, 11, 0),
      IsAllDay: false,
    },
    {
      Id: 2,
      Subject: "Meeting",
      StartTime: new Date(2024, 3, 1, 12, 0),
      EndTime: new Date(2024, 3, 1, 13, 0),
      Status: "completed",
      IsAllDay: false,
    },
  ];

  return (
    <MainContent>
      <Stack sx={{ marginTop: "40px" }}>
        <ScheduleComponent eventSettings={{ dataSource: data }}>
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Agenda" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Day, Week, Month, Agenda]} />
        </ScheduleComponent>
      </Stack>
    </MainContent>
  );
};

export default SchedulerPage;
