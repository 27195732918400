import { Stack, Typography, Divider, Link, IconButton, Button } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { demoAtom } from "../../../context/atoms/Atoms.Storage.jsx";
import { useAtom } from "jotai";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WhatsappModal from "../../../components/chatModalComponent/Whatsapp.Modal.jsx";
import EditIcon from "@mui/icons-material/Edit";
import ModifyVehModal from "./ModifyVeh.Modal.jsx";

const InfoComponent = ({ vehicle, userData, setVehicle }) => {
  const [demo, setDemo] = useAtom(demoAtom);
  const [userId, setUserId] = useState(userData?.id);
  const [whatsappModalIsOpen, setWhatsappModalIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  return (
    <Stack sx={{ position: "relative" }}>
      {editMode && <ModifyVehModal vehicle={vehicle} setVehicle={setVehicle} setEditMode={setEditMode} />}
      <IconButton onClick={() => setEditMode(!editMode)} sx={{ position: "absolute", right: "15px", top: "15px", zIndex: "10" }}>
        <EditIcon />
      </IconButton>
      {whatsappModalIsOpen && (
        <WhatsappModal userId={userId} setUserId={setUserId} onClose={() => setWhatsappModalIsOpen(false)} userName={`${userData.name_first} ${userData.name_last}`} userPhone={userData.phone_number} />
      )}
      <motion.div initial={{ opacity: 0, transform: "translateX(-50px)" }} animate={{ opacity: 1, transform: "translateX(0)" }} transition={{ duration: 0.2 }}>
        <Stack direction={"row"}>
          <Stack justifyContent={"center"} sx={{ marginTop: "0", padding: "15px 25px", width: "100%", maxWidth: "100%", height: "fit-content" }}>
            {(vehicle?.vehicle_manufacturer?.name || vehicle?.vehicle_model?.name || vehicle?.manufacturer_name || vehicle?.vehicle_model_name) && (
              <Stack gap={"10px"} marginBottom={"10px"} justifyContent={"space-between"} direction={"row"}>
                <Stack direction={"row"} gap={"20px"} flexWrap={"wrap"} alignItems={"flex-end"} justifyContent={"flex-start"}>
                  <Stack direction={"row"} gap={"15px"} alignItems={"flex-end"} justifyContent={"flex-start"} flexWrap={"wrap"}>
                    <Typography variant="h3" sx={{ textTransform: "uppercase", fontWeight: "200", color: "#ea5828", textAlign: "left", wordBreak: "break-word", hyphens: "auto" }}>
                      {(vehicle && vehicle?.vehicle_manufacturer?.name) || vehicle?.manufacturer_name}
                    </Typography>
                    <Typography variant="h4" sx={{ textTransform: "uppercase", fontWeight: "200" }}>
                      {(vehicle && vehicle?.vehicle_model?.name?.split(" ")[0]) || vehicle?.model_name}{" "}
                    </Typography>
                    {vehicle?.tech_spec_1 && <Button onClick={() => window.open(vehicle?.tech_spec_1, "_blank")}>Ficha técnica (anverso)</Button>}
                    {vehicle?.tech_spec_2 && <Button onClick={() => window.open(vehicle?.tech_spec_2, "_blank")}>Ficha técnica (reverso)</Button>}
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Divider />
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ height: "fit-content", marginTop: "7px" }}>
              <Stack margin={"10px 0"}>
                {vehicle?.plate && (
                  <Stack gap={"10px"} justifyContent={"space-between"} direction={"row"}>
                    <Typography>{vehicle && vehicle.plate}</Typography>
                  </Stack>
                )}
                {vehicle?.year && (
                  <Stack gap={"10px"} justifyContent={"space-between"} direction={"row"}>
                    <Typography>Año {vehicle && vehicle.year}</Typography>
                  </Stack>
                )}
                {vehicle?.km && (
                  <Stack justifyContent={"space-between"} gap={"10px"} direction={"row"}>
                    <Typography>{vehicle && vehicle.km.toLocaleString()} km</Typography>
                  </Stack>
                )}
                {vehicle?.energy && (
                  <Stack gap={"10px"} justifyContent={"space-between"} direction={"row"}>
                    <Typography justifyContent={"space-between"} sx={{ fontWeight: "200" }}>
                      Combustible{" "}
                    </Typography>
                    <Typography>{vehicle && vehicle.energy}</Typography>
                  </Stack>
                )}
                {vehicle?.date_lastitv && (
                  <Stack Stack gap={"10px"} justifyContent={"space-between"} direction={"row"}>
                    <Typography sx={{ fontWeight: "200" }}> Última ITV </Typography>
                    <Typography>{vehicle && new Date(vehicle?.date_lastitv)?.toLocaleDateString("es-ES", { month: "2-digit", year: "numeric" })}</Typography>
                  </Stack>
                )}
                {vehicle?.date_nextitv && (
                  <Stack Stack gap={"10px"} justifyContent={"space-between"} direction={"row"}>
                    <Typography sx={{ fontWeight: "200" }}> Próxima ITV </Typography>
                    <Typography>{vehicle && new Date(vehicle?.date_nextitv)?.toLocaleDateString("es-ES", { month: "2-digit", year: "numeric" })}</Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Divider />
            <Stack justifyContent={"center"} margin={"10px 0"} sx={{ height: "fit-content" }}>
              <Stack gap={"10px"} justifyContent={"space-between"} direction={"row"}>
                <Stack alignItems={"flex-start"}>
                  <Typography variant="h6" sx={{ textTransform: "uppercase", fontWeight: "300", textAlign: "left" }}>
                    {userData && userData.name_first} {userData && userData.name_last}{" "}
                  </Typography>
                </Stack>
              </Stack>
              <Stack marginTop={"10px"}>
                {vehicle?.year && (
                  <Stack gap={"10px"} justifyContent={"flex-start"} direction={"row"}>
                    {userData && userData.email != "nuevousuario@gmail.com" && (
                      <>
                        <Typography justifyContent={"space-between"} sx={{ fontWeight: "200" }}>
                          Email:
                        </Typography>
                        <Typography> {userData.email}</Typography>
                      </>
                    )}
                  </Stack>
                )}
                {vehicle?.km && (
                  <Stack justifyContent={"flex-start"} alignItems={"center"} gap={"10px"} direction={"row"}>
                    <Typography sx={{ fontWeight: "200" }}> Teléfono: </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <Stack sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                        {userData && userData.phone_number}{" "}
                        <Link href={`https://wa.me/34${userData && userData.phone_number.replace(/\D/g, "")}`} target="_blank">
                          <OpenInNewIcon sx={{ marginTop: "5px", height: "15px", color: "black" }} />
                        </Link>
                      </Stack>
                      <IconButton
                        onClick={() => {
                          setWhatsappModalIsOpen(true);
                        }}
                        sx={{ marginLeft: "50px", height: "40px", width: "40px", right: "10px", top: "0", bottom: "0", margin: "auto", "&:hover": { cursor: "pointer" } }}
                      >
                        <img style={{ height: "20px" }} src="/img/ico_whatsapp.svg" alt="" />
                      </IconButton>
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack style={{ height: "100%!important" }} alignItems={"center"} justifyContent={"center"}></Stack>
        </Stack>
      </motion.div>
    </Stack>
  );
};

export default InfoComponent;
