import { Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/Modal.Component";
import { useForm } from "react-hook-form";
import { createManualIntervention } from "../../../services/API/Intervention.Services.js";
import { getStorageObject } from "../../../utils/LocalStorage";
import { useAtom } from "jotai";
import { checkInterventionsAtom } from "../../../context/atoms/Atoms.Storage";
import CloseIcon from "@mui/icons-material/Close";
import LoadingComponent from "../../../components/Loading.Component.jsx";
import VehicleForm from "./VehicleForm.Component.jsx";
import UserForm from "./UserForm.Component.jsx";

const VehicleModalComponent = ({ modelsState, setVehicleModalIsOpenState, licensePlateState, defaultStep, vehicleDataState, usersState, manufacturersState }) => {
  //HOOKS

  const {
    register: registerVehicle,
    formState: { errors: errorsVehicle },
    handleSubmit: handleSubmitVehicle,
  } = useForm({ mode: "onchange" });
  const {
    register: registerUser,
    formState: { errors: errorsUser },
    handleSubmit: handleSubmitUser,
  } = useForm({ mode: "all" });

  //STATES

  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(defaultStep ? defaultStep : 1);
  const [vehicle, setVehicle] = useState({});
  const [userData, setUserData] = useState();
  const [autoCompleteValueState, setAutoCompleteValueState] = useState();
  const [modelAutocompleteValueState, setModelAutocompleteValueState] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkInterventions, setCheckInterventions] = useAtom(checkInterventionsAtom);
  const [energy, setEnergy] = useState();

  //USE EFFECT

  useEffect(() => {
    if (vehicleDataState) {
      setVehicle(vehicleDataState);
      if (!userData) {
        const user = usersState.find((user) => user.id === vehicleDataState.id_user_owner);
        setUserData(user);
      }
    }
  }, [vehicleDataState, userData, usersState]);

  //FUNCTIONS

  const handleVehicleSubmit = (_vehicleData) => {
    const repairshopId = getStorageObject("repairShopId");
    let _modVehicleData = _vehicleData;
    _modVehicleData.id_vehicle_energy = energy;
    _modVehicleData.manufacturer = autoCompleteValueState.id;
    _modVehicleData.id_vehicle_model = modelAutocompleteValueState.id;
    _modVehicleData.id_user_repairshop = repairshopId;
    setVehicle(_modVehicleData);
    setSteps(steps + 1);
  };

  const handleUserSubmit = (_userData) => {
    setUserData(_userData);
    setSteps(5);
  };

  const generateInterventionData = (_userData) => ({
    vehicle: vehicle,
    user: _userData,
  });

  const handleCreateIntervention = async () => {
    setLoading(true);
    try {
      const interventionData = generateInterventionData(userData);
      const token = getStorageObject("token");
      const { data: interventionResponse } = await createManualIntervention(token, interventionData);
      const InterventionURL = `/intervencion?id2=${interventionResponse.id}&id4=${token}`;
      window.open(InterventionURL, "_blank");
      setVehicleModalIsOpenState(false);
    } catch (error) {
      console.error("Error creating intervention:", error);
    } finally {
      setLoading(false);
      setCheckInterventions(!checkInterventions);
    }
  };

  //RENDER

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <ModalComponent>
      <Stack
        sx={{
          height: "fit-content",
          maxHeight: "90%",
          width: "fit-content",
          padding: "20px",
          paddingTop: "40px",
          backgroundColor: "white",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton sx={{ position: "absolute", left: "5px", top: "5px" }} onClick={() => setVehicleModalIsOpenState(false)}>
          <CloseIcon />
        </IconButton>

        {steps === 1 && (
          <VehicleForm
            energy={energy}
            setEnergy={setEnergy}
            handleSubmit={handleSubmitVehicle}
            register={registerVehicle}
            errors={errorsVehicle}
            manufacturersState={manufacturersState}
            autoCompleteValueState={autoCompleteValueState}
            setAutoCompleteValueState={setAutoCompleteValueState}
            modelAutocompleteValueState={modelAutocompleteValueState}
            setModelAutocompleteValueState={setModelAutocompleteValueState}
            modelsState={modelsState}
            licensePlateState={licensePlateState}
            handleNextStep={handleVehicleSubmit}
          />
        )}

        {steps === 2 && (
          <Stack alignItems="center" justifyContent="center" width="fit-content" gap="10px" padding="0px">
            <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300", width: "100%" }}>¿A quién pertenece este vehiculo?</Typography>
            <TextField variant="standard" sx={{ width: "100%" }} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} label="Número de teléfono" />
            {usersState &&
              phoneNumber &&
              usersState
                .filter((user) => user.phone_number.includes(phoneNumber))
                .map((user) => (
                  <Button
                    key={user.id}
                    sx={{ width: "100%" }}
                    onClick={() => {
                      setUserData(user);
                      setSteps(4);
                    }}
                    variant="outlined"
                  >
                    <Typography>
                      {user.name_first} {user.name_last} <b>{user.phone_number}</b>
                    </Typography>
                  </Button>
                ))}
            {usersState && phoneNumber && usersState.filter((user) => user.phone_number.includes(phoneNumber)).length === 0 && (
              <>
                <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300" }}>El usuario no existe</Typography>
                <Button sx={{ width: "100%" }} variant="contained" onClick={() => setSteps(3)}>
                  Crear usuario
                </Button>
              </>
            )}
            <Button sx={{ width: "100%" }} onClick={() => setSteps(1)}>
              Volver
            </Button>
          </Stack>
        )}

        {steps === 3 && <UserForm handleSubmit={handleSubmitUser} register={registerUser} errors={errorsUser} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} handleNextStep={handleUserSubmit} />}

        {steps >= 4 && (
          <Stack alignItems="center" justifyContent="center" width="fit-content" gap="10px" padding="0px">
            <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300" }}>¿Deseas crear la intervención?</Typography>
            <Button sx={{ width: "50%" }} onClick={handleCreateIntervention} variant="contained">
              Sí
            </Button>
            <Button sx={{ width: "50%" }} onClick={() => setSteps(steps === 4 ? 2 : 3)}>
              Volver
            </Button>
          </Stack>
        )}
      </Stack>
    </ModalComponent>
  );
};

export default VehicleModalComponent;
