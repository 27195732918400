import { ApiCall_GET, ApiCall_POST } from "..";

export const getAllByRepairshopIdVehicles = async (_token) => {
  const response = await ApiCall_GET(`/api/getallbyrepairshopidvehicles`, _token);
  return response;
}

export const getByUserIdVehicles = async (_token) => {
  const response = await ApiCall_GET(`/api/getbyuseridvehicles`, _token);
  return response;
}

export const getAllVehicleData = async (_token, _vehicleId) => {
  const response = await ApiCall_GET(`/api/getallvehicledata?id_vehicle=${_vehicleId}`, _token);
  return response;
}

export const updateVehicleData = async (_token, _data) => {
  const response = await ApiCall_POST(`/api/updatevehicledata`, _data, _token);
  return response;
}

export const uploadTechnicalSheet = async (_token, _id, _part, _file) => {

  let formData = new FormData();
  formData.append('file', _file);

  const response = await ApiCall_POST_Files(`/fire/posttechnicalsheet?id=${_id}&part=${_part}`, formData, _token);

  return response;
}