import React, { useState } from "react";
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { plateValidator, kmsValidator, yearValidator } from "../../../utils/Validators.js";

const VehicleForm = ({
  energy,
  setEnergy,
  handleSubmit,
  register,
  errors,
  manufacturersState,
  autoCompleteValueState,
  setAutoCompleteValueState,
  modelAutocompleteValueState,
  setModelAutocompleteValueState,
  modelsState,
  licensePlateState,
  handleNextStep,
}) => {
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <form id="create-vehicle" style={{ display: "flex", flexDirection: "column", gap: "15px" }} onSubmit={handleSubmit(handleNextStep)}>
      <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300" }}>Introduce los datos del vehículo</Typography>
      <Autocomplete
        disablePortal
        value={autoCompleteValueState}
        onChange={(event, newValue) => {
          setAutoCompleteValueState(newValue);
        }}
        options={manufacturersState}
        sx={{ width: "100%", textAlign: "center", justifyContent: "center", fontSize: "2em", fontWeight: "bolder" }}
        renderInput={(params) => <TextField {...params} label="Marca" variant="standard" />}
      />
      {autoCompleteValueState && (
        <Autocomplete
          disablePortal
          value={modelAutocompleteValueState}
          onChange={(event, newValue) => {
            setModelAutocompleteValueState(newValue);
          }}
          options={modelsState.filter((model) => model.manufacturer === autoCompleteValueState.id)}
          sx={{ width: "100%", textAlign: "center", justifyContent: "center", fontSize: "2em", fontWeight: "bolder" }}
          renderInput={(params) => <TextField {...params} label="Modelo" variant="standard" />}
        />
      )}
      <TextField variant="standard" label="Versión" {...register("version")} />
      <TextField helperText={errors.km?.message} required variant="standard" type="number" label="Kilometraje" {...register("km", { validate: kmsValidator })} />
      <TextField helperText={errors.year?.message} required variant="standard" type="number" label="Año" {...register("year", { validate: yearValidator })} />
      <FormControl sx={{ width: "100%", display: "flex" }}>
        <InputLabel sx={{ left: "-13px" }} id="demo-simple-select-label">
          Combustible
        </InputLabel>
        <Select
          MenuProps={{ disablePortal: true }}
          variant="standard"
          labelId="energy-label"
          id="energy"
          value={energy}
          onChange={(e) => {
            setEnergy(e.target.value);
          }}
        >
          <MenuItem value={1}>Diésel</MenuItem>
          <MenuItem value={2}>Gasolina</MenuItem>
          <MenuItem value={3}>Híbrido</MenuItem>
          <MenuItem value={4}>Eléctrico</MenuItem>
          <MenuItem value={5}>GLP</MenuItem>
        </Select>
      </FormControl>

      <TextField helperText={errors.plate?.message} required variant="standard" defaultValue={licensePlateState} label="Matrícula" {...register("plate", { validate: plateValidator })} />
      <Button disabled={Object.keys(errors).length > 0} sx={{ marginTop: "20px" }} type="submit" variant="contained">
        Siguiente
      </Button>
    </form>
  );
};

export default VehicleForm;
